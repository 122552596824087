import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageConfigMaxBrandDiscount from 'Pages/config/max-brand-discount';
import PageConfigPasswordRules from 'Pages/config/password-rules';
import PageConfigPurposes from 'Pages/config/purposes';
import PageConfigPurpose from 'Pages/config/purpose';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}/max-brand-discount`} component={ PageConfigMaxBrandDiscount } />
			<Route exact path={`${match.url}/password-rules`} component={ PageConfigPasswordRules } />
			<Route exact path={`${match.url}/purposes`} component={ PageConfigPurposes } />
			<Route exact path={`${match.url}/purposes/create`} component={ PageConfigPurpose } />
			<Route exact path={`${match.url}/purposes/edit/:id`} component={ PageConfigPurpose } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;