import {
	PURPOSES_CREATE,
	PURPOSES_CREATE_SUCCESS,
	PURPOSES_CREATE_FAIL,
	PURPOSES_UPDATE,
	PURPOSES_UPDATE_SUCCESS,
	PURPOSES_UPDATE_FAIL,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	PURPOSES_GET_DETAILS,
	PURPOSES_GET_DETAILS_SUCCESS,
	PURPOSES_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const purposesCreate = (data) => ({
	type: PURPOSES_CREATE,
	payload: data,
});

export const purposesCreateSuccess = (data) => {
	return ({
		type: PURPOSES_CREATE_SUCCESS,
		payload: data,
	});
};

export const purposesCreateFail = (error) => ({
	type: PURPOSES_CREATE_FAIL,
	payload: error,
});

export const purposesUpdate = (data) => ({
	type: PURPOSES_UPDATE,
	payload: data,
});

export const purposesUpdateSuccess = (data) => {
	return ({
		type: PURPOSES_UPDATE_SUCCESS,
		payload: data,
	});
};

export const purposesUpdateFail = (error) => ({
	type: PURPOSES_UPDATE_FAIL,
	payload: error,
});

export const purposesFindAllByPageAndOptions = (params) => ({
	type: PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const purposesFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const purposesFindAllByPageAndOptionsFail = (error) => ({
	type: PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const purposesGetDetails = (id) => ({
	type: PURPOSES_GET_DETAILS,
	payload: id,
});

export const purposesGetDetailsSuccess = (data) => {
	return ({
		type: PURPOSES_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const purposesGetDetailsFail = (error) => ({
	type: PURPOSES_GET_DETAILS_FAIL,
	payload: error,
});

export const purposesGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'purpose',
		params,
	},
});
