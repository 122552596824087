import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import ReactTable from 'react-table';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import IntlMessages from 'Util/IntlMessages';
import {
	purposesFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as purposesReducer from 'Redux/purposes/reducer';

class PageConfigPurposes extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.config' },
			{ id: 'menu.config.purposes', href: '/config/purposes' },
		];
		this.columns = [
			{
				id: 'name',
				Header: 'Name',
				accessor: (d) => (
					<NavLink to={ `/app/config/purposes/edit/${d.id}` }>
						<span className="list-item-heading mb-1 truncate">{ d.name }</span>
					</NavLink>
				),
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				id: 'status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					let className = 'badge-primary';
					switch (d.status) {
					case 0:
						className = 'badge-light';
						break;
					}
					return (
						<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ d.status === 0 ? 'Disabled' : 'Enabled' }</span>
					);
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/config/purposes/edit/${d.id}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('purposes');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.purposesFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { purposesByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('purposes');
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<div className="float-left">
							<BreadcrumbContainer
								heading={ <IntlMessages id="config.purposes.heading" /> }
								items={ this.breadcrumb }
								match={ this.props.match }
							/>
						</div>
						<div className="float-right">
							<NavLink to="/app/config/purposes/create" className="btn btn-lg btn-primary">
								CREATE NEW
							</NavLink>
						</div>
						<div className="clearfix"></div>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									data={ purposesByPage.get('data') }
									pages={ purposesByPage.get('max_page') }
									loading={ status === 'fetching' }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	purposesReducer.selector,
	{
		purposesFindAllByPageAndOptions,
		errorReset,
	}
)(PageConfigPurposes);