import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	WAREHOUSES_UPDATE,
	WAREHOUSES_UPDATE_SUCCESS,
	WAREHOUSES_UPDATE_FAIL,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	WAREHOUSES_GET_DETAILS,
	WAREHOUSES_GET_DETAILS_SUCCESS,
	WAREHOUSES_GET_DETAILS_FAIL,
	WAREHOUSES_GET_IP_WHITELISTS,
	WAREHOUSES_GET_IP_WHITELISTS_SUCCESS,
	WAREHOUSES_GET_IP_WHITELISTS_FAIL,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case WAREHOUSES_UPDATE:
		return state.set('warehouse', 'submitting');
	case WAREHOUSES_UPDATE_SUCCESS:
		return state.set('warehouse', 'submitted');
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS:
		return state.set('ipWhitelists', 'submitting');
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS:
		return state.set('ipWhitelists', 'submitted');
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('warehouses', 'fetching');
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('warehouses', 'fetched');
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('warehouses', 'has-errors');
	case WAREHOUSES_GET_DETAILS:
		return state.set('warehouse', 'fetching');
	case WAREHOUSES_GET_DETAILS_SUCCESS:
		return state.set('warehouse', 'fetched');
	case WAREHOUSES_UPDATE_FAIL:
	case WAREHOUSES_GET_DETAILS_FAIL:
		return state.set('warehouse', 'has-errors');
	case WAREHOUSES_GET_IP_WHITELISTS:
		return state.set('ipWhitelists', 'fetching');
	case WAREHOUSES_GET_IP_WHITELISTS_SUCCESS:
		return state.set('ipWhitelists', 'fetched');
	case WAREHOUSES_GET_IP_WHITELISTS_FAIL:
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL:
		return state.set('ipWhitelists', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'warehouse' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'warehouse' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'warehouse' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case WAREHOUSES_UPDATE_SUCCESS:
	case WAREHOUSES_GET_DETAILS_SUCCESS:
		return action.payload.wh_code;
	default:
		return state;
	}
};

const warehousesByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const warehouseReducer = (state = new Map(), action) => {
	switch (action.type) {
	case WAREHOUSES_GET_DETAILS:
		return new Map();
	case WAREHOUSES_UPDATE_SUCCESS:
	case WAREHOUSES_GET_DETAILS_SUCCESS:
		return state.set(action.payload.wh_code, action.payload);
	default:
		return state;
	}
};

const ipWhitelistsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case WAREHOUSES_GET_IP_WHITELISTS:
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS:
		return new Map();
	case WAREHOUSES_GET_IP_WHITELISTS_SUCCESS:
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS:
		return state.set(action.payload.warehouse_code, action.payload.whitelist);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'warehouse' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'warehouse' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status           : statusReducer,
	current          : currentReducer,
	warehousesByPage : warehousesByPageReducer,
	warehouse        : warehouseReducer,
	ipWhitelists     : ipWhitelistsReducer,
	options          : optionsReducer,
});

export const selector = createStructuredSelector({
	status             : (state) => state.warehouses.status,
	current            : (state) => state.warehouses.current,
	warehousesByPage   : (state) => state.warehouses.warehousesByPage,
	warehouse          : (state) => state.warehouses.warehouse,
	ipWhitelists       : (state) => state.warehouses.ipWhitelists,
	options            : (state) => state.warehouses.options,
	errors             : (state) => state.errors.get('warehouse'),
	errorsIPWhitelists : (state) => state.errors.get('warehouseIPWhitelists'),
});