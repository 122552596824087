import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	WAREHOUSES_UPDATE,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSES_GET_DETAILS,
	WAREHOUSES_GET_IP_WHITELISTS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	warehousesUpdateSuccess,
	warehousesUpdateFail,
	warehousesFindAllByPageAndOptionsSuccess,
	warehousesFindAllByPageAndOptionsFail,
	warehousesGetDetailsSuccess,
	warehousesGetDetailsFail,
	warehousesGetIPWhitelistsSuccess,
	warehousesGetIPWhitelistsFail,
	warehousesBatchUpdateIPWhitelistsSuccess,
	warehousesBatchUpdateIPWhitelistsFail,
} from './actions';

export function* watchWarehousesUpdate() {
	yield takeEvery(WAREHOUSES_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouse/update`, data);
			return response.data;
		},
		warehousesUpdateSuccess,
		warehousesUpdateFail
	));
}

export function* watchWarehousesFindAllByPageAndOptions() {
	yield takeEvery(WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouse/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		warehousesFindAllByPageAndOptionsSuccess,
		warehousesFindAllByPageAndOptionsFail
	));
}

export function* watchWarehousesGetDetails() {
	yield takeEvery(WAREHOUSES_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouse/details`, { params: { wh_code: data } });
			return response.data;
		},
		warehousesGetDetailsSuccess,
		warehousesGetDetailsFail
	));
}

export function* watchWarehousesGetIPWhitelists() {
	yield takeEvery(WAREHOUSES_GET_IP_WHITELISTS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouseIpWhitelist/findAllByWarehouse`, { params: { warehouse_code: data } });
			return response.data;
		},
		warehousesGetIPWhitelistsSuccess,
		warehousesGetIPWhitelistsFail
	));
}

export function* watchWarehousesBatchUpdateIPWhitelists() {
	yield takeEvery(WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouseIpWhitelist/batchUpdateByWarehouse`, data);
			return response.data;
		},
		warehousesBatchUpdateIPWhitelistsSuccess,
		warehousesBatchUpdateIPWhitelistsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchWarehousesUpdate),
		fork(watchWarehousesFindAllByPageAndOptions),
		fork(watchWarehousesGetDetails),
		fork(watchWarehousesGetIPWhitelists),
		fork(watchWarehousesBatchUpdateIPWhitelists),
	]);
}