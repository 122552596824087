import {
	WAREHOUSES_UPDATE,
	WAREHOUSES_UPDATE_SUCCESS,
	WAREHOUSES_UPDATE_FAIL,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	WAREHOUSES_GET_DETAILS,
	WAREHOUSES_GET_DETAILS_SUCCESS,
	WAREHOUSES_GET_DETAILS_FAIL,
	WAREHOUSES_GET_IP_WHITELISTS,
	WAREHOUSES_GET_IP_WHITELISTS_SUCCESS,
	WAREHOUSES_GET_IP_WHITELISTS_FAIL,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const warehousesUpdate = (data) => ({
	type: WAREHOUSES_UPDATE,
	payload: data,
});

export const warehousesUpdateSuccess = (data) => {
	return ({
		type: WAREHOUSES_UPDATE_SUCCESS,
		payload: data,
	});
};

export const warehousesUpdateFail = (error) => ({
	type: WAREHOUSES_UPDATE_FAIL,
	payload: error,
});

export const warehousesFindAllByPageAndOptions = (params) => ({
	type: WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const warehousesFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const warehousesFindAllByPageAndOptionsFail = (error) => ({
	type: WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const warehousesGetDetails = (warehouseCode) => ({
	type: WAREHOUSES_GET_DETAILS,
	payload: warehouseCode,
});

export const warehousesGetDetailsSuccess = (data) => {
	return ({
		type: WAREHOUSES_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const warehousesGetDetailsFail = (error) => ({
	type: WAREHOUSES_GET_DETAILS_FAIL,
	payload: error,
});

export const warehousesGetIPWhitelists = (warehouseCode) => ({
	type: WAREHOUSES_GET_IP_WHITELISTS,
	payload: warehouseCode,
});

export const warehousesGetIPWhitelistsSuccess = (data) => {
	return ({
		type: WAREHOUSES_GET_IP_WHITELISTS_SUCCESS,
		payload: data,
	});
};

export const warehousesGetIPWhitelistsFail = (error) => ({
	type: WAREHOUSES_GET_IP_WHITELISTS_FAIL,
	payload: error,
});

export const warehousesBatchUpdateIPWhitelists = (data) => ({
	type: WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS,
	payload: data,
});

export const warehousesBatchUpdateIPWhitelistsSuccess = (data) => {
	return ({
		type: WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS,
		payload: data,
	});
};

export const warehousesBatchUpdateIPWhitelistsFail = (error) => ({
	type: WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL,
	payload: error,
});

export const warehousesGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'warehouse',
		params,
	},
});
