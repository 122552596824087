import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	PURPOSES_CREATE,
	PURPOSES_UPDATE,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PURPOSES_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	purposesCreateSuccess,
	purposesCreateFail,
	purposesUpdateSuccess,
	purposesUpdateFail,
	purposesFindAllByPageAndOptionsSuccess,
	purposesFindAllByPageAndOptionsFail,
	purposesGetDetailsSuccess,
	purposesGetDetailsFail,
} from './actions';

export function* watchPurposesCreate() {
	yield takeEvery(PURPOSES_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/purpose/create`, data);
			return response.data;
		},
		purposesCreateSuccess,
		purposesCreateFail
	));
}

export function* watchPurposesUpdate() {
	yield takeEvery(PURPOSES_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/purpose/update`, data);
			return response.data;
		},
		purposesUpdateSuccess,
		purposesUpdateFail
	));
}

export function* watchPurposesFindAllByPageAndOptions() {
	yield takeEvery(PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/purpose/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		purposesFindAllByPageAndOptionsSuccess,
		purposesFindAllByPageAndOptionsFail
	));
}

export function* watchPurposesGetDetails() {
	yield takeEvery(PURPOSES_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/purpose/details`, { params: { id: data } });
			return response.data;
		},
		purposesGetDetailsSuccess,
		purposesGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchPurposesCreate),
		fork(watchPurposesUpdate),
		fork(watchPurposesFindAllByPageAndOptions),
		fork(watchPurposesGetDetails),
	]);
}