import React, { PureComponent, Fragment } from 'react';
import { Row, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import Select from 'react-select';
import thousands from 'thousands';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomSelectInput from 'Components/CustomSelectInput';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import Tab from 'Components/Tab';
import {
	transactionsFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as transactionsReducer from 'Redux/transactions/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageTransactions extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			transactionStatus: {
				label: 'All',
				value: 'all',
			},
			startDate: moment().subtract(90, 'days').startOf('day'),
			endDate: moment().endOf('day'),
			currentTab: 'salesQ',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.transaction', href: '/transaction/list' },
		];
		this.tabs = [
			{
				id: 'salesQ',
				name: 'Sales Q',
			},
			{
				id: 'salesR',
				name: 'Sales R',
			},
			{
				id: 'onHoldSalesOrder',
				name: 'Sales Hold',
			},
			{
				id: 'serviceQ',
				name: 'Service Q',
			},
			{
				id: 'serviceR',
				name: 'Service R',
			},
			{
				id: 'onHoldServiceMemo',
				name: 'Service Hold',
			},
			{
				id: 'transferIn',
				name: 'Transfer In',
			},
			{
				id: 'transferOut',
				name: 'Transfer Out',
			},
			// {
			// 	id: 'shopOrder',
			// 	name: 'Shop Order',
			// },
		];
		this.statuses = [
			{
				label: 'All',
				value: 'all',
			},
			{
				label: 'Complete',
				value: 'complete',
			},
			{
				label: 'Incomplete',
				value: 'incomplete',
			},
			{
				label: 'Cancel',
				value: 'cancel',
			},
		];
		this.columns = [
			{
				id: 'date',
				Header: 'Date',
				accessor: (d) => (
					<p className="text-muted">{ d.transaction_date ? moment(d.transaction_date).format('YYYY-MM-DD HH:mm:ss') : '' }</p>
				),
			},
			{
				id: 'trx_no',
				Header: 'Trx No',
				accessor: (d) => (
					<NavLink to={ `/app/transaction/view/${d.transaction_number}` }>
						<p className="text-muted">{ d.transaction_number }</p>
					</NavLink>
				),
			},
			{
				id: 'doc_type',
				Header: 'Type',
				width: 100,
				accessor: (d) => (
					<p className="text-muted">{ d.doc_type }</p>
				),
			},
			{
				id: 'client_code',
				Header: 'Member No',
				accessor: (d) => (
					<p className="text-muted">{ `${d.member && d.member.member_code ? d.member.member_code : ''}` }</p>
				),
			},
			{
				id: 'amount',
				Header: 'Amount',
				width: 100,
				sortable: false,
				accessor: (d) => (
					<p className="text-muted">{ d.transaction_amount < 0 ? '-' : '' }${ thousands(Math.abs(d.transaction_amount)) }</p>
				),
			},
			{
				id: 'status',
				Header: 'Status',
				accessor: (d) => (
					<p className="text-muted">{ d.transaction_status_name_en }</p>
				),
			},
			{
				id: 'btns',
				Header: '',
				width: 150,
				accessor: (d) => (
					<ButtonGroup>
						<NavLink
							to={ `/app/transaction/view/${d.transaction_number}` }
							className="btn btn-primary btn-xs"
						>
							<i className="simple-icon-magnifier"></i> View
						</NavLink>
						<NavLink
							to={ `/app/transaction/print/${d.transaction_number}` }
							className="btn btn-info btn-xs"
						>
							<i className="simple-icon-printer"></i> Print
						</NavLink>
					</ButtonGroup>
				),
			},
		];
		this.columnsTransfer = [
			{
				id: 'created_date',
				Header: 'Date',
				accessor: (d) => (
					<p className="text-muted">{ d.created_date }</p>
				),
			},
			{
				id: 'transaction_number',
				Header: 'Trx No',
				sortable: false,
				accessor: (d) => (
					<p className="text-muted">{ d.transaction_number }</p>
				),
			},
			{
				id: 'wh_desc',
				Header: 'Warehouse',
				sortable: false,
				accessor: (d) => (
					<Fragment>
						{
							d.wh_desc_from && (
								<p className="text-muted">From: { d.wh_desc_from }</p>
							)
						}
						{
							d.wh_desc_to && (
								<p className="text-muted">To: { d.wh_desc_to }</p>
							)
						}
					</Fragment>
				),
			},
			{
				id: 'item_count',
				Header: 'Qty',
				sortable: false,
				accessor: (d) => (
					<p className="text-muted">{ d.item_count }</p>
				),
			},
			{
				id: 'status',
				Header: 'Status',
				accessor: (d) => (
					<p className="text-muted text-uppercase">{ d.status }</p>
				),
			},
		];
		this.columnsTransferIn = [
			...this.columnsTransfer,
			{
				id: 'btns',
				Header: '',
				width: 150,
				accessor: (d) => (
					<ButtonGroup>
						<NavLink
							to={ `/app/stock-transfer/view/in/${d.order_type}/${d.transaction_number}` }
							className="btn btn-primary btn-xs"
						>
							<i className="simple-icon-magnifier"></i> View
						</NavLink>
						<NavLink
							to={ `/app/stock-transfer/print/in/${d.order_type}/${d.transaction_number}` }
							className="btn btn-info btn-xs"
						>
							<i className="simple-icon-printer"></i> Print
						</NavLink>
					</ButtonGroup>
				),
			},
		];
		this.columnsTransferOut = [
			...this.columnsTransfer,
			{
				id: 'btns',
				Header: '',
				width: 150,
				accessor: (d) => (
					<ButtonGroup>
						<NavLink
							to={ `/app/stock-transfer/view/out/${d.order_type}/${d.transaction_number}` }
							className="btn btn-primary btn-xs"
						>
							<i className="simple-icon-magnifier"></i> View
						</NavLink>
						<NavLink
							to={ `/app/stock-transfer/print/out/${d.order_type}/${d.transaction_number}` }
							className="btn btn-info btn-xs"
						>
							<i className="simple-icon-printer"></i> Print
						</NavLink>
					</ButtonGroup>
				),
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangeTransactionStatusHandler = this.onChangeTransactionStatusHandler.bind(this);
		this.onChangeStartDateHandler = this.onChangeStartDateHandler.bind(this);
		this.onChangeEndDateHandler = this.onChangeEndDateHandler.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('transactions');
	}

	fetchData(state = null) {
		if (!state) {
			state = this.state.tableState;
		}
		const { keyword, currentTab, transactionStatus, startDate, endDate } = this.state;
		let param = {
			page: state.page + 1,
			size: state.pageSize,
			doc_type: null,
			is_on_hold: false,
			is_service_memo: false,
			is_current_shop: false,
			is_backend: true,
		};
		switch (currentTab) {
		case 'salesQ':
			param.doc_type = 'DP1';
			param.is_on_hold = false;
			param.is_service_memo = false;
			break;
		case 'salesR':
			param.doc_type = 'SA1';
			param.is_on_hold = false;
			param.is_service_memo = false;
			break;
		case 'onHoldSalesOrder':
			param.doc_type = 'HM1';
			param.is_on_hold = true;
			param.is_service_memo = false;
			break;
		case 'serviceQ':
			param.doc_type = 'DP1';
			param.is_on_hold = false;
			param.is_service_memo = true;
			break;
		case 'serviceR':
			param.doc_type = 'SA3';
			param.is_on_hold = false;
			param.is_service_memo = true;
			break;
		case 'onHoldServiceMemo':
			param.doc_type = 'HM1';
			param.is_on_hold = true;
			param.is_service_memo = true;
			break;
		case 'transferIn':
			param.doc_type = 'TI';
			break;
		case 'transferOut':
			param.doc_type = 'TO';
			break;
		case 'shopOrder':
			param.doc_type = 'OR1';
			param.is_on_hold = false;
			param.is_service_memo = false;
			break;
		}

		switch (currentTab) {
		case 'transferIn':
		case 'transferOut':
			param = {
				page: state.page + 1,
				size: state.pageSize,
				sort_column: 'st_date',
				sort_direction: (state.sorted.length > 0) ? (state.sorted[0].desc ? 'desc' : 'asc') : 'desc',
				status: 'all',
				doc_type: param.doc_type,
				is_current_shop: false,
				is_backend: true,
			};
			if (keyword) {
				param.keyword = keyword;
			}
			if (transactionStatus && transactionStatus.value) {
				param.status = transactionStatus.value;
			}
			if (startDate) {
				param.start_date = startDate.format('YYYY-MM-DD') + ' 00:00:00';
			}
			if (endDate) {
				param.end_date = endDate.format('YYYY-MM-DD') + ' 23:59:59';
			}
			this.props.transactionsFindAllByPageAndOptions(param);
			break;
		default:
			if (state.sorted.length > 0) {
				param.sort_column = state.sorted[0].id;
				param.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
			}
			if (keyword) {
				param.keyword = keyword;
			}
			if (transactionStatus && transactionStatus.value) {
				param.transaction_status = transactionStatus.value;
				if (param.doc_type === 'HM1') {
					param.transaction_status = 'all';
				}
			}
			switch (param.doc_type) {
			case 'DP1':
			case 'SA1':
			case 'SA3':
				break;
			default:
				delete param.doc_type;
				break;
			}
			if (startDate) {
				param.transaction_start_date = startDate.format('YYYY-MM-DD') + ' 00:00:00';
			}
			if (endDate) {
				param.transaction_end_date = endDate.format('YYYY-MM-DD') + ' 23:59:59';
			}
			this.setState({
				tableState: state,
			});
			this.props.transactionsFindAllByPageAndOptions(param);
		}
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	onChangeTransactionStatusHandler(option) {
		this.setState({
			transactionStatus: option,
		});
	}

	onChangeStartDateHandler(date) {
		this.setState({
			startDate: date,
		});
	}

	onChangeEndDateHandler(date) {
		this.setState({
			endDate: date,
		});
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
		}, this.fetchData);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { transactionsByPage } = this.props;
		const { keyword, transactionStatus, startDate, endDate, currentTab } = this.state;
		const status = this.props.status.get('transactions');
		const isLoading = status === 'fetching';
		let columns = this.columns;
		switch (currentTab) {
		case 'transferIn':
			columns = this.columnsTransferIn;
			break;
		case 'transferOut':
			columns = this.columnsTransferOut;
			break;
		}
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="transactions.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Card>
					<CardBody>
						<form onSubmit={ this.onSubmitHandler }>
							<Tab
								tabs={ this.tabs }
								current={ currentTab }
								onChange={ this.onChangeTabHandler }
							/>
							<Row className="mt-3">
								<Colxx sm="12">
									<div className="search d-inline-block mr-1 mb-1 align-top w-100">
										<input
											type="text"
											placeholder="Search by trx no., issued by or salesman code"
											className="w-100"
											value={ keyword }
											onChange={ this.onChangeKeywordHandler }
											disabled={ isLoading }
										/>
									</div>
								</Colxx>
							</Row>
							<Row className="mt-3">
								<Colxx sm="4">
									<div className="form-group has-float-label">
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"
											name="form-field-name"
											value={ transactionStatus }
											onChange={ this.onChangeTransactionStatusHandler }
											options={ this.statuses }
											isDisabled={ isLoading }
										/>
										<span>Transaction status</span>
									</div>
								</Colxx>
								<Colxx sm="3">
									<div className="form-group has-float-label">
										<DatePicker
											selected={ startDate }
											selectsStart
											startDate={ startDate }
											endDate={ endDate }
											onChange={ this.onChangeStartDateHandler }
											disabled={ isLoading }
											dateFormat="YYYY-MM-DD"
											isClearable
										/>
										<span>Transaction Date (From)</span>
									</div>
								</Colxx>
								<Colxx sm="3">
									<div className="form-group has-float-label">
										<DatePicker
											selected={ endDate }
											selectsStart
											startDate={ startDate }
											endDate={ endDate }
											onChange={ this.onChangeEndDateHandler }
											disabled={ isLoading }
											dateFormat="YYYY-MM-DD"
											isClearable
										/>
										<span>Transaction Date (To)</span>
									</div>
								</Colxx>
								<Colxx sm="2">
									<Button size="lg" color="primary" type="submit" disabled={ isLoading }>
										SEARCH
									</Button>
								</Colxx>
							</Row>

							<p className="text-muted mt-1 mb-0">
								<small>Number of transactions: { thousands(transactionsByPage.get('total')) }</small>
							</p>
						</form>
					</CardBody>
				</Card>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ transactionsByPage.get('data') }
									pages={ transactionsByPage.get('max_page') }
									loading={ isLoading }
									onFetchData={ this.fetchData }
									columns={ columns }
									minRows={ 0 }
									showPageJump={ false }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	transactionsReducer.selector,
	{
		transactionsFindAllByPageAndOptions,
		errorReset,
	}
)(PageTransactions);
