import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	PURPOSES_CREATE,
	PURPOSES_CREATE_SUCCESS,
	PURPOSES_CREATE_FAIL,
	PURPOSES_UPDATE,
	PURPOSES_UPDATE_SUCCESS,
	PURPOSES_UPDATE_FAIL,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	PURPOSES_GET_DETAILS,
	PURPOSES_GET_DETAILS_SUCCESS,
	PURPOSES_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PURPOSES_CREATE:
	case PURPOSES_UPDATE:
		return state.set('purpose', 'submitting');
	case PURPOSES_CREATE_SUCCESS:
	case PURPOSES_UPDATE_SUCCESS:
		return state.set('purpose', 'submitted');
	case PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('purposes', 'fetching');
	case PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('purposes', 'fetched');
	case PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('purposes', 'has-errors');
	case PURPOSES_GET_DETAILS:
		return state.set('purpose', 'fetching');
	case PURPOSES_GET_DETAILS_SUCCESS:
		return state.set('purpose', 'fetched');
	case PURPOSES_CREATE_FAIL:
	case PURPOSES_UPDATE_FAIL:
	case PURPOSES_GET_DETAILS_FAIL:
		return state.set('purpose', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'purpose' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'purpose' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'purpose' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case PURPOSES_CREATE:
	case PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case PURPOSES_CREATE_SUCCESS:
	case PURPOSES_UPDATE_SUCCESS:
	case PURPOSES_GET_DETAILS_SUCCESS:
		return action.payload.id;
	default:
		return state;
	}
};

const purposesByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const purposeReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PURPOSES_GET_DETAILS:
		return new Map();
	case PURPOSES_CREATE_SUCCESS:
	case PURPOSES_UPDATE_SUCCESS:
	case PURPOSES_GET_DETAILS_SUCCESS:
		return state.set(action.payload.id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'purpose' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'purpose' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status         : statusReducer,
	current        : currentReducer,
	purposesByPage : purposesByPageReducer,
	purpose        : purposeReducer,
	options        : optionsReducer,
});

export const selector = createStructuredSelector({
	status         : (state) => state.purposes.status,
	current        : (state) => state.purposes.current,
	purposesByPage : (state) => state.purposes.purposesByPage,
	purpose        : (state) => state.purposes.purpose,
	options        : (state) => state.purposes.options,
	errors         : (state) => state.errors.get('purpose'),
});